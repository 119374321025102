import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SuspenseContent from '../../../../containers/SuspenseContent'

const PDFViewer = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  
  const queryParams = new URLSearchParams(location.search);
  const pdfUrl = queryParams.get('file');
  const fileName = queryParams.get('title');
  
  useEffect(() => {
    document.title = fileName ? decodeURIComponent(fileName) : 'PDF Viewer';

    if (fileName) {
      const friendlyFileName = decodeURIComponent(fileName);
      window.history.replaceState(null, '', `/pdf-viewer/${friendlyFileName}`);
    }
  }, [fileName]);
  
  const handleIframeLoad = () => {
    setLoading(false);
  };
  
  return (
    <div style={{ height: '100vh' }}>
      {loading && <SuspenseContent />}
      {pdfUrl && (
        <>
          <iframe 
            src={pdfUrl} 
            title={fileName} 
            style={{ width: '100%', height: '100%', border: 'none' }}
            onLoad={handleIframeLoad}
          />
        </>
      )}
    </div>
  );
};

export default PDFViewer;
