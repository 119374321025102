module.exports = Object.freeze({
  STALE_TIME: 0,
  TIME_OUT_REQUEST: 1000 * 60 * 1,
  MODAL_BODY_TYPES: {
    USER_DETAIL: "USER_DETAIL",
    LEAD_ADD_NEW: "LEAD_ADD_NEW",
    CONFIRMATION: "CONFIRMATION",
    STORE_DISP_INDEX: "STORE_DISP_INDEX",
    PERMISSION_EDIT: "PERMISSION_EDIT",
    CUSTOMER_GROUP_EDIT: "CUSTOMER_GROUP_EDIT",
    AUTHORITY_ITEM_EDIT: "AUTHORITY_ITEM_EDIT",
    // DEPARTMENT_ADD: "DEPARTMENT_ADD",
    // DEPARTMENT_EDIT: "DEPARTMENT_EDIT",
    // CUSTOMER_EDIT: "CUSTOMER_EDIT",
    MESSAGE: "MESSAGE",
    SELECT_EMPLOYEE: "SELECT_EMPLOYEE",
    SELECT_CUSTOMER: "SELECT_CUSTOMER",
    SELECT_CUSTOMER_EMPLOYEE: "SELECT_CUSTOMER_EMPLOYEE",
    SELECT_SEKO_CUSTOMER_EMPLOYEE: "SELECT_SEKO_CUSTOMER_EMPLOYEE",
    SELECT_BUKKEN: "SELECT_BUKKEN",
    SELECT_CONST: "SELECT_CONST",
    SELECT_LOGIN_USER: "SELECT_LOGIN_USER",
    ERROR: "ERROR",
    TEXT: "TEXT",
    DEPARTMENT_ADD: "DEPARTMENT_ADD",
    WARNING: "WARNING", // 警告ダイアログ
    DEPARTMENT_SELECT: "DEPARTMENT_SELECT",
    ANDES_BACKUP_LOCK: "ANDES_BACKUP_LOCK",
    ANDES_BACKUP_EDIT: "ANDES_BACKUP_EDIT",
    ANDES_BACKUP_DELETE: "ANDES_BACKUP_DELETE",
    DAIRY_REPORT_TEMPLATE_ITEM_ADD: "DAIRY_REPORT_TEMPLATE_ITEM_ADD",
    ATTENDANCE_MESSAGE: "ATTENDANCE_MESSAGE",
    ATTENDANCE_HOLIDAY: "ATTENDANCE_HOLIDAY",
    DAIRY_REPORT_LIST_ITEMS_ITEM_ADD: "DAIRY_REPORT_LIST_ITEMS_ITEM_ADD",
    LOCAL_CONFIG_ADD: "LOCAL_CONFIG_ADD", // 対象地域追加ダイアログ
    LOCAL_CONFIG_UPINFO: "LOCAL_CONFIG_UPINFO", // 地域設定更新情報ダイアログ
    LOCAL_CONFIG_ATFINFO: "LOCAL_CONFIG_ATFINFO", // 地域設定書式情報ダイアログ
    LOCAL_CONFIG_LOCATION_ADD: "LOCAL_CONFIG_LOCATION_ADD", //地域追加ダイアログ
    LOCAL_CONFIG_LOCATION_EDIT: "LOCAL_CONFIG_LOCATION_EDIT", //地域編集ダイアログ
    LOCAL_CONFIG_LOCATION_HISTORY: "LOCAL_CONFIG_LOCATION_HISTORY", // 地域設定更新履歴ダイアログ
    LOCAL_CONFIG_DELIVERYCONTROL: "LOCAL_CONFIG_DELIVERYCONTROL", //　地域設定配信開始・停止ダイアログ
    LOCAL_CONFIG_COMMENT_EDIT: "LOCAL_CONFIG_COMMENT_EDIT", //　地域設定コメント編集
    LOCAL_CONFIG_FILE_EDIT: "LOCAL_CONFIG_FILE_EDIT", //　地域設定ファイル編集
    LOCAL_CONFIG_FOLDER_EDIT: "LOCAL_CONFIG_FOLDER_EDIT", //　地域設定フォルダ編集
    LOCAL_CONFIG_OPERATION_LOG: "LOCAL_CONFIG_OPERATION_LOG", // 会社設定変更履歴ダイアログ
    BUKKEN_SETUP_ROOM: "BUKKEN_SETUP_ROOM",
    BUKKEN_SETUP_ROOM_DETAIL: "BUKKEN_SETUP_ROOM_DETAIL",
    BUKKEN_RELATED: "BUKKEN_RELATED",
    BUKKEN_JOIN_MEMBER: "BUKKEN_JOIN_MEMBER",
    BUKKEN_FILE: "BUKKEN_FILE",
    BUKKEN_DEPARTMENT: "BUKKEN_DEPARTMENT",
    BUKKEN_CUSTOMER: "BUKKEN_CUSTOMER",
    BUKKEN_EMPLOYEE: "BUKKEN_EMPLOYEE",
    BUKKEN_SEKO_EMPLOYEE: "BUKKEN_SEKO_EMPLOYEE",
    BUKKEN_CUSTOMER_EMPLOYEE: "BUKKEN_CUSTOMER_EMPLOYEE",
    BUKKEN_SEKO_CUSTOMER_EMPLOYEE: "BUKKEN_SEKO_CUSTOMER_EMPLOYEE",
    BUKKEN_CONST_MASTER: "BUKKEN_CONST_MASTER",
    BUKKEN_DELETE_DETAIL: "BUKKEN_DELETE_DETAIL",
    BUKKEN_EDIT_IMAGE_NAME: "BUKKEN_EDIT_IMAGE_NAME",
    BUKKEN_MANAGE_FOLDER_IMAGE: "BUKKEN_MANAGE_FOLDER_IMAGE",
    BUKKEN_STRUCTURE_FOLDER: "BUKKEN_STRUCTURE_FOLDER",
    BUKKEN_MANAGE_MASTER: "BUKKEN_MANAGE_MASTER",
    BUKKEN_ITEM_MASTER: "BUKKEN_ITEM_MASTER",
    BUKKEN_IMAGE_FOLDER_MASTER: "BUKKEN_IMAGE_FOLDER_MASTER",
    BUKKEN_ADD_PATTERN_NAME: "BUKKEN_ADD_PATTERN_NAME",
    BUKKEN_DELETE_FOLDER: "BUKKEN_DELETE_FOLDER",
    BUKKEN_DELETE_MASTER: "BUKKEN_DELETE_MASTER",
    BUKKEN_COMFIRM_MASTER: "BUKKEN_COMFIRM_MASTER",
    BUKKEN_DELETE_PATTER_NAME: "BUKKEN_DELETE_PATTER_NAME",
    JOIN_MEMBER_ADVANCED: "JOIN_MEMBER_ADVANCED",
    CUSTOMER_ADVANCED: "CUSTOMER_ADVANCED",
    MULTI_SELECT: "MULTI_SELECT",
    SEARCH_STATUS: "SEARCH_STATUS",
    TOTAL_DETAIL: "TOTAL_DETAIL",
    INFO_DETAIL: "INFO_DETAIL",
    REFERENCE_INFO: "REFERENCE_INFO",

    //QUOTATION_MODAL
    EXPORT_QUOTATION: "EXPORT_QUOTATION",
    EXPORT_INVOICE: "EXPORT_INVOICE",
    CREATE_INVOICE: "CREATE_INVOICE",
    CUSTOMER_SEARCH_EMPLOYEE: "CUSTOMER_SEARCH_EMPLOYEE",
    JOIN_MEMBER_SEARCH: "JOIN_MEMBER_SEARCH",
    DELETE_QUOTATION_LIST: "DELETE_QUOTATION_LIST",
    DELETE_INVOICE_LIST: "DELETE_INVOICE_LIST",
    CONFIRM_REDIRECT_BUKKEN: "CONFIRM_REDIRECT_BUKKEN",
    CSV_IMPORT: "CSV_IMPORT",
    MAILBOX_CONFIRM_QUOTATION: "MAILBOX_CONFIRM_QUOTATION",
    MAILBOX_CONFIRM_INVOICE: "MAILBOX_CONFIRM_INVOICE",
    
    //SHASHIN_MODAL
    DELETE_PHOTOTBOOK: "DELETE_PHOTOTBOOK",
    DEFAULT: ""
  },

  APP_TYPES: {
    BUKKEN_APP: "BUKKEN_APP"
  },

  RIGHT_DRAWER_TYPES: {
    NOTIFICATION: "NOTIFICATION",
    CALENDAR_EVENTS: "CALENDAR_EVENTS"
  },

  CONFIRMATION_MODAL_CLOSE_TYPES: {
    LEAD_DELETE: "LEAD_DELETE"
  },

  BASE_LIST: [
    { key: 1, value: "岡山支店" },
    { key: 2, value: "東京支店" },
    { key: 3, value: "大阪支店" },
    { key: 4, value: "福岡営業所" },
    { key: 5, value: "仙台営業所" },
    { key: 6, value: "名古屋営業所" },
    { key: 7, value: "営業企画部" },
    { key: 8, value: "東日本サポートセンター" },
    { key: 9, value: "西日本サポートセンター" },
    { key: 10, value: "新規事業部" }
  ],

  CATEGORY_LIST: [
    { value: 1, label: "工事管理" },
    { value: 2, label: "CADサービス" }
  ],

  COMPANY_AUTHORITY: [
    { labelTitle: "企業作成", value: 1, checked: true },
    { labelTitle: "企業編集", value: 2, checked: true },
    { labelTitle: "企業論理削除", value: 4, checked: true },
    { labelTitle: "企業論理削除復帰", value: 8, checked: true },
    { labelTitle: "企業完全削除", value: 16, checked: true },
    { labelTitle: "企業検索", value: 32, checked: true },
    { labelTitle: "企業情報取得", value: 64, checked: true }
  ],

  CONFIG_AUTHORITY: [
    { labelTitle: "ストレージ容量変更", value: 1, checked: true },
    { labelTitle: "版数管理変更", value: 2, checked: true },
    { labelTitle: "バックアップ変更", value: 4, checked: true },
    { labelTitle: "設定取得", value: 8, checked: true }
  ],

  USER_INFO_AUTHORITY: [{ labelTitle: "システム情報一覧取得", value: 1, checked: true }],

  SYSTEM_COMPANY_UUID: {
    SYSTEM_COMPANY_UUID: "00000000-0000-0000-0000-000000000000"
  },

  QUERY_KEY: {
    RELATED_PROPERTY: "RELATED_PROPERTY",
    LOCATION_MST: "LOCATION_MST",
    CONTRACT_CLASS: "CONTRACT_CLASS",
    PROPERTY_CLASSTION: "PROPERTY_CLASSTION",
    BUKKEN_DETAIL: "BUKKEN_DETAIL",
    BUKKEN_SEARCH: "BUKKEN_SEARCH",
    BUKKEN_SEARCH_ADVANCED: "BUKKEN_SEARCH_ADVANCED",
    BUKKEN_SEARCH_INFO: "BUKKEN_SEARCH_INFO",
    BUKKEN_ITEM_SEARCH: "BUKKEN_ITEM_SEARCH",
    BUKKEN_ITEM_MST_SEARCH: "BUKKEN_ITEM_MST_SEARCH",
    BUKKEN_DETAIL_ADVANCED: "BUKKEN_DETAIL_ADVANCED",
    GET_ALL_FOLDER_BUKKEN: "GET_ALL_FOLDER_BUKKEN",
    GET_FOLDER_CHILDREN: "GET_FOLDER_CHILDREN",
    DOWNLOAD_FILE: "DOWNLOAD_FILE",
    DOWNLOAD_FOLDER: "DOWNLOAD_FOLDER",
    DELETE_FILE: "DELETE_FILE",
    DELETE_FOLDER: "DELETE_FOLDER",
    DOWNLOAD_MULTI_FILES: "DOWNLOAD_MULTI_FILES",
    REGISTER_FOLDER: "REGISTER_FOLDER",
    REGISTER_FILE: "REGISTER_FILE",
    CREATE_FILE: "CREATE_FILE",
    CHANGE_FOLDER_NAME: "CHANGE_FOLDER_NAME",
    CHANGE_FILE_NAME: "CHANGE_FILE_NAME",
    REGISTER_IMAGE_FOLDER: "REGISTER_IMAGE_FOLDER",
    CHANGE_IMAGE_FOLDER_NAME: "CHANGE_IMAGE_FOLDER_NAME",
    GET_BUKKEN_RELATED_INFO: "GET_BUKKEN_RELATED_INFO",
    DELETE_PHOTO_BUKKEN_FOLDER: "DELETE_PHOTO_BUKKEN_FOLDER",
    CONST_MST_ALL: "CONST_MST_ALL",
    GET_INFO_FILE: "GET_INFO_FILE",
    DELETE_BUKKEN_FILE: "DELETE_BUKKEN_FILE",
    DELETE_BUKKEN_FOLDER: "DELETE_BUKKEN_FOLDER",
    DELETE_BUKKEN_PHOTO: "DELETE_BUKKEN_PHOTO",
    DELETE_MST_FILE: "DELETE_MST_FILE",
    DELETE_MST_FOLDER: "DELETE_MST_FOLDER",
    GET_THUMBNAILS: "GET_THUMBNAILS",

    GET_ALL_BUKKEN_CLASS_MST: "GET_ALL_BUKKEN_CLASS_MST",
    GET_BUKKEN_CLASS_MST_INFO: "GET_BUKKEN_CLASS_MST_INFO",
    CHANGE_BUKKEN_CLASS_MST: "CHANGE_BUKKEN_CLASS_MST",
    CREATE_BUKKEN_CLASS_MST: "CREATE_BUKKEN_CLASS_MST",
    DELETE_BUKKEN_CLASS_MST: "DELETE_BUKKEN_CLASS_MST",

    GET_ALL_BUKKEN_TAB_MST: "GET_ALL_BUKKEN_TAB_MST",
    CHANGE_BUKKEN_TAB_MST: "CHANGE_BUKKEN_TAB_MST",
    CREATE_BUKKEN_TAB_MST: "CREATE_BUKKEN_TAB_MST",
    DELETE_BUKKEN_TAB_MST: "DELETE_BUKKEN_TAB_MST",
    EDIT_BUKKEN_TAB_LIST: "EDIT_BUKKEN_TAB_LIST",

    GET_ALL_LOCATION_MST: "GET_ALL_LOCATION_MST",
    CHANGE_LOCATION_MST: "CHANGE_LOCATION_MST",
    CREATE_LOCATION_MST: "CREATE_LOCATION_MST",
    DELETE_LOCATION_MST: "DELETE_LOCATION_MST",

    GET_ALL_CONST_TYPE_MST: "GET_ALL_CONST_TYPE_MST",
    CHANGE_CONST_TYPE_MST: "CHANGE_CONST_TYPE_MST",
    CREATE_CONST_TYPE_MST: "CREATE_CONST_TYPE_MST",
    DELETE_CONST_TYPE_MST: "DELETE_CONST_TYPE_MST",

    GET_ALL_TEMPLATE_MST: "GET_ALL_TEMPLATE_MST",
    CHANGE_TEMPLATE_MST: "CHANGE_TEMPLATE_MST",
    CREATE_TEMPLATE_MST: "CREATE_TEMPLATE_MST",
    DELETE_TEMPLATE_MST: "DELETE_TEMPLATE_MST",

    DEPARTMENT_INFO: "DEPARTMENT_INFO",
    EMPLOYEE_SEARCH: "EMPLOYEE_SEARCH",
    CUSTOMER_SEARCH: "CUSTOMER_SEARCH",
    CUSTOMER_EMPLOYEE: "CUSTOMER_EMPLOYEE",
    CUSTOMER_GROUP: "CUSTOMER_GROUP",
    JOIN_MEMBER: "JOIN_MEMBER",

    GET_BUKKEN_PHOTO_FOLDERS_CHILDREN: "GET_BUKKEN_PHOTO_FOLDERS_CHILDREN",

    GET_COMPANY_INFO: "GET_COMPANY_INFO",

    //QUOTATION
    GET_QUOTATION_MST: "GET_QUOTATION_MST",
    GET_QUOTATION_ITEM_LIST: "GET_QUOTATION_ITEM_LIST",
    GET_QUOTATION_DETAIL: "GET_QUOTATION_DETAIL",
    QUOTATION_LIST: "QUOTATION_LIST",
    QUOTATION_LAST_INPUT: "QUOTATION_LAST_INPUT",
    QUOTATION_CREATE: "QUOTATION_CREATE",
    QUOTATION_EDIT: "QUOTATION_EDIT",
    QUOTATION_ITEM_CREATE: "QUOTATION_ITEM_CREATE,",
    QUOTATION_ITEM_DELETE: "QUOTATION_ITEM_DELETE",
    QUOTATION_ITEM_EDIT: "QUOTATION_ITEM_EDIT",
    IMPORT_ITEM: "IMPORT_ITEM",

    //INVOICE
    GET_INVOICE_LIST: "GET_INVOICE_LIST",
    GET_INVOICE_MST: "GET_INVOICE_MST",
    GET_INVOICE_LAST_INPUT: "GET_INVOICE_LAST_INPUT",
    GET_INVOICE_DETAIL: "GET_INVOICE_DETAIL",
    INVOICE_CREATE: "INVOICE_CREATE",

    // SHASHI KANRI
    CHECK_PHOTO: "CHECK_PHOTO",
    GET_PHOTOBOOK: "GET_PHOTOBOOK",
    PHOTOBOOK_LIST_BY_BUKKEN: "PHOTOBOOK_LIST_BY_BUKKEN",
    GET_TEMPLATE_PHOTOBOOK: "GET_TEMPLATE_PHOTOBOOK",
    GET_PHOTOS_IN_MONTH: "GET_PHOTOS_IN_MONTH",
    GET_PHOTO_NOTE: "GET_PHOTO_NOTE",
    GET_LIST_TEMPLATE_PHOTOBOOK: "GET_LIST_TEMPLATE_PHOTOBOOK",
    GET_THUMBNAIL_HIGHT_QUALITY: "GET_THUMBNAIL_HIGHT_QUALITY",
  },
  ITEM_TYPE: {
    BLANK: -1, // 空白
    INPUT: 0, // 文字
    DATE: 1, // 日付
    SELECT: 2, // セレクトボックス
    RADIO_BUTTON: 3, // ラジオボタン
    CHECKBOX: 4, // チェックボックス
    EMPLOYEE: 5, // 社員
    DEPARTMENT: 6, // 部署
    CUSTOMER: 7, // 得意先
    CUSTOMER_EMPLOYEE: 8, // 得意先担当者
    CONST_MASTER: 9, // 工種
    CONTRACT_TYPE: 10, // 請負区分
    ROOM: 11, // 部屋
    STATUS: 12, // 進捗状況
    RELATED_BUKKEN: 13, // 関連物件
    JOIN_MEMBER: 14, // 参加者
    LABEL: 15, // ラベル
    PHONE: 16, // 電話番号
    QUALIFICATION: 17, // 資格情報
    ADDRESS: 18, // 住所
    SEKO_EMPLOYEE: 19, // (施工会社の)社員（自社と特定の会社を状況によって出す）

    SPAN_TYPE_LIST: [15, 18] //　一行使用する種類の一覧(ラベルと住所)
  },
  PREFIX: {
    JOIN_MEMBER_ADVANCED: "JOIN_MEMBER_ADVANCED"
  },
  BUKKEN_TAB_UUID: "00000000-0000-0000-0000-000000000000",
  USER_UUID: "00000000-0000-0000-0000-000000000000",
  PERSONAL_SETTING: 1,
  COMPANY_SETTING: 2,
  ITEM_TYPE_REMOVE: -1,

  ARRAY_ITEM_CODE: ["1", "2", "3", "4", "5", "6", "7"],
  INVALID: -1,
  INVALID_FOR_IMCOMPLETE_DATA: -2,
  SETTING_VALIDATE: ["1", "2"],

  SORT_ORDER_DEFAULT: 9999,
  PROGRESS_CODITION: ["完了", "失注"],

  CONFIG_DEFAULT_WIDTH: {
    ITEM_CODE: {
      1: "300px",
      2: "250px",
      3: "150px",
      4: "250px",
      5: "250px",
      6: "100px",
      7: "200px"
    },
    ITEM_TYPE: {
      0: "150px",
      1: "130px",
      2: "150px",
      3: "150px",
      4: "200px",
      5: "150px",
      6: "150px",
      7: "200px",
      8: "150px",
      9: "100px",
      10: "100px",
      11: "100px",
      12: "100px",
      13: "150px"
    }
  },

  SEARCH_SCREEN: {
    BUKKEN_LIST: "BUKKEN_LIST",
    JOIN_MEMBER_MODAL: "JOIN_MEMBER_MODAL",
    JOIN_MEMBER_CUSTOMER_EMPLOYEE_MODAL: "JOIN_MEMBER_CUSTOMER_EMPLOYEE_MODAL",
    CUSTOMER_MODAL: "CUSTOMER_MODAL",
    CUSTOMER_EMPLOYEE_MODAL: "CUSTOMER_EMPLOYEE_MODAL"
  },

  CASE_PATTERN_SEARCH: "pattern_search",
  WARNING_CUSTOMER_UUID: "WARNING_CUSTOMER_UUID",

  TYPE_DATE: {
    MATCHING: "0",
    WITHIN_RANGE: "1",
    OUT_OF_RANGE: "2",
    BEFORE: "3",
    AFTER: "4"
  },

  BASIC_INFO_UUID_LIST: {
    "00000000000000000000000000000001": "progress",
    "00000000000000000000000000000002": "parent_bukken_uuid",
    "00000000000000000000000000000003": "bukken_num",
    "00000000000000000000000000000004": "bukken_name",
    "00000000000000000000000000000005": "zip_code",
    "00000000000000000000000000000006": "address",
    "00000000000000000000000000000007": "prefectures",
    "00000000000000000000000000000008": "municipality",
    "00000000000000000000000000000009": "district",
    "00000000000000000000000000000010": "town_village",
    "00000000000000000000000000000011": "house_number",
    "00000000000000000000000000000012": "building_name",
    "00000000000000000000000000000013": "site_name",
    "00000000000000000000000000000014": "bukken_class_uuid",
    "00000000000000000000000000000015": "location_uuid",
    "00000000000000000000000000000016": "contract_class",
    "00000000000000000000000000000017": "bukken_order_name",
    "00000000000000000000000000000018": "bukken_orderer_manager_name",
    "00000000000000000000000000000019": "bukken_construct_name",
    "00000000000000000000000000000020": "bukken_construct_manager_name",
    "00000000000000000000000000000021": "own_manager_uuid",
    "00000000000000000000000000000022": "department_uuid",
    "00000000000000000000000000000023": "bukken_member",
    "00000000000000000000000000000024": "construct_start_dt",
    "00000000000000000000000000000025": "construct_end_dt"
  },
  
  MIN_BUKKEN_NUM_LENGTH: 3,
  INITIAL_PROGRESS_CONDITION: ["受付", "現調", "見積", "内示", "受注", "申請", "着工", "竣工", "検査", "請求", "入金"],
  
  //QUOTATION_INVOICE
  BUKKEN_UUID_DEFAULT: "00000000-0000-0000-0000-000000000000",
  QUOTATION_STATUS_DRAFT: "00000000-0000-0000-0000-000000000001",
  QUOTATION_STATUS_REGISTER: "00000000-0000-0000-0000-000000000005",
  TAB_ACTIVE_QUOTATION: "TAB_QUOTATION",
  TAB_ACTIVE_INVOICE: "TAB_INVOICE",
  TABLE_COLOR_QUOTATION: {
    "00000000-0000-0000-0000-000000000001": {
      backgroundColor: "#FFFFFF",
      textColor: "#000000"
    },
    "00000000-0000-0000-0000-000000000005": {
      backgroundColor: "#FFFFFF",
      textColor: "#000000"
    },
    "00000000-0000-0000-0000-000000000010": {
      backgroundColor: "#90D26D", //green
      textColor: "#000000"
    },
    "00000000-0000-0000-0000-000000000015": {
      backgroundColor: "#008DDA", //blue
      textColor: "#000000"
    },
    "00000000-0000-0000-0000-000000000020": {
      backgroundColor: "#D80032", //red
      textColor: "#FFFFFF"
    },
    "00000000-0000-0000-0000-000000000090": {
      backgroundColor: "#F7F5F2", //gray
      textColor: "#000000"
    }
  },

  //INVOICE
  INVOICE_TABLE_COLOR: {
    "00000000-0000-0000-0000-000000000001": {
      backgroundColor: "#FFFFFF",
      textColor: "#000000"
    },
    "00000000-0000-0000-0000-000000000005": {
      backgroundColor: "#FFFFFF",
      textColor: "#000000"
    },
    "00000000-0000-0000-0000-000000000010": {
      backgroundColor: "#FFFFFF",
      textColor: "#000000"
    },
    "00000000-0000-0000-0000-000000000015": {
      backgroundColor: "#FFFFFF",
      textColor: "#CCD3CA"
    },
    "00000000-0000-0000-0000-000000000020": {
      backgroundColor: "#FFFFFF",
      textColor: "#000000"
    },
    "00000000-0000-0000-0000-000000000090": {
      backgroundColor: "#FFFFFF",
      textColor: "#CCD3CA"
    }
  },
  TAX_KIND_UUID: [
    "00000000-0000-0000-0000-000000000001", //10%
    "00000000-0000-0000-0000-000000000010" //軽減8%
  ],
  TAX_SHOW_UUID: [
    "00000000-0000-0000-0000-000000000001", //税別表示
    "00000000-0000-0000-0000-000000000005", //税込表示
    "00000000-0000-0000-0000-000000000010" //税込表示(免税)
  ],

  TAX_FRACTION_UUID: [
    "00000000-0000-0000-0000-000000000001", //切り捨て
    "00000000-0000-0000-0000-000000000005", //切り上げ
    "00000000-0000-0000-0000-000000000010" //四捨五入
  ],

  WITHHOLDING_TAX_UUID: [
    "00000000-0000-0000-0000-000000000001", //なし
    "00000000-0000-0000-0000-000000000005", //あり (復興税あり)
    "00000000-0000-0000-0000-000000000010" //あり (復興税なし)
  ],
  SHOW_BUTTON_EDIT: "00000000-0000-0000-0000-000000000005",

  OPTION_SELECTBOX_LIMIT_DATE_INVOICE: ["翌月末", "翌々月末", "任意"],
  LIMIT_DT_SELECTION: [
    1, //翌月末
    10, //翌々月末
    100 //任意
  ],
  QUOTATION_FLG_DISPLAY: 0,
  QUOTATION_FLG_UN_DISPLAY: 1,
  STATUS_CODE_DO_NOT_PERMISSION: "210",
  TITLE_CSV_TABLE: ["品名", "型番", "単位", "単価", "原価", "税区分"],
  // CHARACTER_REGEX_FOLDER_NAME: /[\/\\:*?"<>|,;]/
  CHARACTER_REGEX_FILE_NAME: /[\/\\:*?"<>|,;¥]/,
  CHARACTER_REGEX_FOLDER_NAME: /[\/\\:*?"<>|¥]/,

  ALL_SYSTEM_UUID: "00000000-0000-0000-0000-000000000000",
  
  //SHASHI KANRI
  COLOR_WEEKEND : {
    SATURDAY: "text-blue-500",
    SUNDAY: "text-red-500",
  },  
  EXPORT_FILE_TYPE: {
    PDF: 0,
    EXCEL: 1
  }
});

